import type { FC } from "react";
import type { EntityNavigation } from "@motain/xpa-proto-files-web/lib/types/entity_navigation";
import { EntityNavigationGrid } from "./entity-navigation-grid/EntityNavigationGrid";
import { EntityNavigationScrollable } from "./entity-navigation-scrollable/EntityNavigationScrollable";

export const EntityNavigationComponent: FC<NonNullable<EntityNavigation>> = (
  props,
) => {
  const { isScrollable } = props;

  const Tag = isScrollable ? EntityNavigationScrollable : EntityNavigationGrid;

  return <Tag {...props}></Tag>;
};
