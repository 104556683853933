import type { VisibilityComponent } from "@motain/xpa-proto-files-web/lib/types/visibility_component";
import type { FC } from "react";
import { VisibilityAlert } from "../../components/visibility-alert/VisibilityAlert";
import { webExperienceFetch } from "@/utils/webExperienceFetch";
import { FETCH_METHOD } from "@/constants/fetch-method";
import type { Layout as XpaLayout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import { isNullable } from "@/types/isNullable";
import { isNonNullable } from "@/types/isNonNullable";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { lazyImportLayoutDecoder } from "@/utils/lazy-import-layout-decoder";
import { remoteLogger } from "@/utils/remoteLogger";

type VisibilityPaginationProps = VisibilityComponent & {
  addAditionalComponent?: (layout: XpaLayout) => void;
};

export const VisibilityPagination: FC<VisibilityPaginationProps> = (props) => {
  const { link, addAditionalComponent } = props;

  const onVisible = async () => {
    if (
      isNullable(link) ||
      isNullable(link.urlPath) ||
      isNullable(addAditionalComponent) ||
      !envBasedConfig.featureFlags.paginationHome
    ) {
      return;
    }

    try {
      const decoder = await lazyImportLayoutDecoder();

      const options = {
        method: FETCH_METHOD.GET,
        decoder: decoder,
      };

      const response = await webExperienceFetch(link.urlPath, options);
      if (isNonNullable(response.decoded)) {
        addAditionalComponent(response.decoded);
      }
    } catch (error) {
      remoteLogger.error("Error in visibilityPagination", error);
    }
  };
  return <VisibilityAlert onVisible={() => void onVisible()} />;
};
