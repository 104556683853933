import type { FC } from "react";
import React, { useMemo } from "react";
import type { VideoGalleryComponent } from "@motain/xpa-proto-files-web/lib/types/video_gallery";
import styles from "./VideoGallery.module.scss";
import { SectionHeader } from "@/xpa-components/section-header/SectionHeader";
import classNames from "classnames";
import { HorizontalScrollingContainer } from "@/components/horizontal-scrolling-container/HorizontalScrollingContainer";
import { VideoTeaser } from "@/components/video-teaser/VideoTeaser";

export const VideoGallery: FC<VideoGalleryComponent> = ({
  items,
  sectionHeader,
}) => {
  const containerItems = useMemo(() => {
    return items.map((video) => ({
      key: video.uiKey,
      liClassName: styles.videoGalleryVideoTeaserContainer,
      element: <VideoTeaser {...video} titleClassName="title-8-bold" />,
    }));
  }, [items]);

  return (
    <div>
      <div className={styles.videoGalleryHeader}>
        {sectionHeader && <SectionHeader {...sectionHeader} />}
        <p
          className={classNames(
            "title-7-medium",
            styles.videoGalleryItemsCount,
          )}
        >
          ({items.length})
        </p>
      </div>
      <div className={styles.videoGalleryScrollingContainer}>
        <HorizontalScrollingContainer
          desktopChunkItemsCount={4}
          items={containerItems}
        ></HorizontalScrollingContainer>
      </div>
    </div>
  );
};
