import type { FC } from "react";
import type { EntityNavigation } from "@motain/xpa-proto-files-web/lib/types/entity_navigation";
import styles from "./EntityNavigationGrid.module.scss";
import { EntityNavigationLink } from "../entity-navigation-link/EntityNavigationLink";
import classNames from "classnames";

export const EntityNavigationGrid: FC<NonNullable<EntityNavigation>> = (
  props,
) => {
  const { title, links } = props;

  return (
    <article className={styles.container}>
      <header className={classNames(styles.title, "title-7-bold")}>
        {title}
      </header>
      <ul className={styles.list}>
        {links.map((linkItem) => {
          return (
            <li key={linkItem.urlPath}>
              <EntityNavigationLink {...linkItem} hasLogoRounded={true} />
            </li>
          );
        })}
      </ul>
    </article>
  );
};
