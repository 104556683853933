import type { FC } from "react";
import type { YahooBanner as YahooBannerProps } from "@motain/xpa-proto-files-web/lib/types/yahoo_banner";
import { YahooLogo } from "@/components/yahoo-logo/YahooLogo";
import styles from "./YahooBanner.module.scss";

export const YahooBanner: FC<YahooBannerProps> = () => {
  return (
    <div className={styles.container}>
      <YahooLogo className="title-8-regular" />
    </div>
  );
};
