import type { Layout } from "@motain/xpa-proto-files-web/lib/types/xpa";
import { useState } from "react";

export type UpdateLayoutFunction = (fetchedLayout: Layout) => void;
type UpdateLayoutReturn = [Layout, UpdateLayoutFunction];

export const useUpdateLayout = (layout: Layout): UpdateLayoutReturn => {
  const [layoutState, setLayoutState] = useState<Layout>(layout);

  const updateLayout = (fetchedLayout: Layout) => {
    const newLayoutContainers = [
      ...layoutState.containers,
      ...fetchedLayout.containers,
    ];
    setLayoutState({ ...layout, containers: newLayoutContainers });
  };

  return [layoutState, updateLayout];
};
