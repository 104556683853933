import type { Layout } from "@motain/xpa-proto-files-web/lib/runtime/xpa";
import type { GetServerSideProps, InferGetServerSidePropsType } from "next";
import type { FC } from "react";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import type { XpaSupportedComponentsResolverProps } from "@/components/xpa-layout/XpaLayout";
import { XpaLayout } from "@/components/xpa-layout/XpaLayout";
import { Title } from "@/xpa-components/title/Title";
import styles from "@/styles/pages/Home.module.scss";
import { Gallery } from "@/xpa-components/gallery/Gallery";
import { HorizontalSeparator } from "@/xpa-components/horizontal-separator/HorizontalSeparator";
import { EntityNavigationComponent } from "@/xpa-components/entity-navigation/EntityNavigation";
import { XpaGoogleAdsPlaceholder } from "@/modules/ads";
import { VideoGallery } from "@/xpa-components/video-gallery/VideoGallery";
import { NativeAppsPromotionBannerComponent } from "@/xpa-components/native-apps-promotion-banner/NativeAppsPromotionBanner";
import { ValuePropositionBanner } from "@/xpa-components/value-proposition-banner/ValuePropositionBanner";
import { UpcomingMatches } from "@/xpa-components/upcoming-matches/UpcomingMatches";
import { MatchGallery } from "@/xpa-components/match-gallery/MatchGallery";
import Pattern from "@/public/next-assets/zigzag-pattern-left.svg";
import {
  StreamName,
  useRegisterStream,
  useRegisterXpaStream,
} from "@/modules/analytics";
import { VisibilityPagination } from "@/xpa-components/visibility-pagination/VisibilityPagination";
import { useUpdateLayout } from "@/utils/useUpdateLayout";
import { fetchXpaLayoutHomeWithResponseInfo } from "@/utils/fetchXPALayoutHome";
import { YahooBanner } from "@/xpa-components/yahoo-banner/YahooBanner";

const CONTAINER_COUNT_LIMIT = envBasedConfig.featureFlags.paginationHome
  ? undefined
  : 5;

const Home: FC<InferGetServerSidePropsType<typeof getServerSideProps>> = (
  props,
) => {
  useRegisterStream(StreamName.HomeStream);
  useRegisterXpaStream(props);
  const [layout, updateLayout] = useUpdateLayout(props);

  return (
    <div className={styles.xpaContainer}>
      <XpaLayout
        layout={layout}
        XpaSupportedComponentsResolver={XpaSupportedComponentsResolver}
        updateLayout={updateLayout}
        containersCountLimit={CONTAINER_COUNT_LIMIT}
      />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<Layout> = async (ctx) => {
  const layoutResponse = await fetchXpaLayoutHomeWithResponseInfo(ctx);

  if (layoutResponse.status === 404) {
    return { notFound: true };
  } else {
    return { props: layoutResponse.layout };
  }
};

export default Home;

const XpaSupportedComponentsResolver: XpaSupportedComponentsResolverProps = ({
  contentType,
  onPagination,
}) => {
  switch (contentType.$case) {
    case "title":
      return <Title {...contentType.title} />;
    case "gallery":
      return (
        <>
          <div className={styles.patternContainer}>
            <Pattern className={styles.pattern} />
          </div>
          <Gallery {...contentType.gallery} />
        </>
      );
    case "videoGallery":
      return <VideoGallery {...contentType.videoGallery} />;
    case "googleAdsPlaceholder":
      return <XpaGoogleAdsPlaceholder {...contentType.googleAdsPlaceholder} />;
    case "yahooBanner":
      if (envBasedConfig.featureFlags.yahoo) {
        return <YahooBanner />;
      }
      return null;
    case "matchGallery":
      return <MatchGallery {...contentType.matchGallery} />;
    case "visibilityComponent":
      return (
        <VisibilityPagination
          {...contentType.visibilityComponent}
          addAditionalComponent={onPagination}
        ></VisibilityPagination>
      );
    case "horizontalSeparator":
      return <HorizontalSeparator {...contentType.horizontalSeparator} />;
    case "entityNavigation":
      return <EntityNavigationComponent {...contentType.entityNavigation} />;
    case "upcomingMatches":
      return (
        <div className="enforced-dark">
          <UpcomingMatches {...contentType.upcomingMatches} isDark />
        </div>
      );
    case "nativeAppsPromotionBanner":
      return (
        <div className="enforced-light">
          <NativeAppsPromotionBannerComponent
            {...contentType.nativeAppsPromotionBanner}
          />
        </div>
      );
    case "proposition":
      return (
        <ValuePropositionBanner
          {...contentType.proposition}
          isPreloaded={true}
        />
      );
    default:
      return null;
  }
};
