import React, { useMemo } from "react";
import type { FC } from "react";
import classnames from "classnames";
import type { MatchGalleryComponent } from "@motain/xpa-proto-files-web/lib/types/match_gallery";
import { SectionHeader } from "@/xpa-components/section-header/SectionHeader";
import { HorizontalScrollingContainer } from "@/components/horizontal-scrolling-container/HorizontalScrollingContainer";
import { MatchCardComponent } from "@/xpa-components/match-card/MatchCard";
import styles from "./MatchGallery.module.scss";

export const MatchGallery: FC<MatchGalleryComponent> = ({
  matchCards,
  sectionHeader,
}) => {
  const containerItems = useMemo(() => {
    return matchCards.map((card) => ({
      element: <MatchCardComponent {...card} />,
      key: card.uiKey,
    }));
  }, [matchCards]);

  return (
    <div>
      {sectionHeader && (
        <div className={styles.matchGalleryHeader}>
          <SectionHeader {...sectionHeader} />
          <p
            className={classnames(
              "title-7-medium",
              styles.matchGalleryItemsCount,
            )}
          >
            ({matchCards.length})
          </p>
        </div>
      )}
      <HorizontalScrollingContainer
        desktopChunkItemsCount={3}
        tabletLandscapeChunkItemsCount={3}
        tabletPortraitChunkItemsCount={2}
        mobileChunkItemsCount={1}
        items={containerItems}
      />
    </div>
  );
};
