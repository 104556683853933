import type { FC } from "react";
import type { MatchCard } from "@motain/xpa-proto-files-web/lib/types/match_card";
import styles from "./MatchCard.module.scss";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { SimpleMatchCard } from "./simple-match-card/SimpleMatchCard";
import classNames from "classnames";
import Link from "next/link";

export const MatchCardComponent: FC<MatchCard> = (props) => {
  const onMatchCardHandle = () => {
    trackingService.sendXpaTracking(
      props.trackingEvents,
      EventType.EVENT_CLICK,
    );
  };

  return (
    <Link
      href={props.link}
      className={classNames(styles.matchCard)}
      onClick={onMatchCardHandle}
    >
      <SimpleMatchCard {...props} />
    </Link>
  );
};
