import type { FC } from "react";
import { useMemo } from "react";
import type { EntityNavigation } from "@motain/xpa-proto-files-web/lib/types/entity_navigation";
import styles from "./EntityNavigationScrollable.module.scss";
import { HorizontalScrollingContainer } from "@/components/horizontal-scrolling-container/HorizontalScrollingContainer";
import { EntityNavigationLink } from "../entity-navigation-link/EntityNavigationLink";
import classNames from "classnames";

export const EntityNavigationScrollable: FC<NonNullable<EntityNavigation>> = ({
  title,
  links,
}) => {
  const linksItems = useMemo(
    () =>
      links.map((linkItem) => ({
        element: <EntityNavigationLink {...linkItem} />,
        key: linkItem.urlPath,
      })),
    [links],
  );

  return (
    <article className={styles.container}>
      <header className={classNames("title-7-bold", styles.title)}>
        {title}
      </header>

      <div className={styles.links}>
        <HorizontalScrollingContainer
          items={linksItems}
          desktopChunkItemsCount={4}
          mobileChunkItemsCount={2}
          tabletLandscapeChunkItemsCount={4}
          tabletPortraitChunkItemsCount={4}
        />
      </div>
    </article>
  );
};
