import { ButtonLink } from "@/components/button/ButtonLink";
import { imageUrlService } from "@/services/image-url-service";
import { trackingService } from "@/modules/analytics";
import type { NativeAppsPromotionBanner } from "@motain/xpa-proto-files-web/lib/types/native_apps_promotion_banner";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import classNames from "classnames";
import type { FC } from "react";
import styles from "./NativeAppsPromotionBanner.module.scss";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { isNonNullable } from "@/types/isNonNullable";
import type { ImageObject } from "@motain/xpa-proto-files-web/lib/types/image";
import { isNullable } from "@/types/isNullable";

const createImage = (image?: ImageObject): string => {
  if (isNullable(image)) {
    return "";
  }

  const backgroundImgSrc = imageUrlService.buildImgSrc({
    ratio: 2.754,
    width: 1680,
    src: image.path,
  });

  if (backgroundImgSrc.isOk()) {
    return backgroundImgSrc.value;
  }

  return "";
};

/**
 * This component is meant to be used in light mode only
 **/
export const NativeAppsPromotionBannerComponent: FC<
  NativeAppsPromotionBanner
> = (props) => {
  const { cta, backgroundImage, logos, title } = props;

  const hasMoreThanOneLogo = logos.length > 1;

  return (
    <article
      className={classNames(
        styles.container,
        hasMoreThanOneLogo && styles.multipleLogos,
      )}
      style={{
        "--background-image": `url(${createImage(backgroundImage)})`,
      }}
    >
      {!hasMoreThanOneLogo &&
        logos.map((logo) => (
          <ImageWithFallback
            key={logo.alt}
            src={logo.path}
            alt={logo.alt}
            width={56}
            height={56}
            className={styles.logo}
          />
        ))}
      <p className={classNames("title-2-regular", styles.text)}>{title}</p>
      {isNonNullable(cta) && (
        <ButtonLink
          href={cta.urlPath}
          className={styles.cta}
          onClick={() => {
            trackingService.sendXpaTracking(
              cta.trackingEvents,
              EventType.EVENT_CLICK,
            );
          }}
        >
          {cta.name}
        </ButtonLink>
      )}
      {hasMoreThanOneLogo && (
        <ul className={styles.logosList}>
          {logos.map((logo) => (
            <li key={logo.alt}>
              <ImageWithFallback
                src={logo.path}
                alt={logo.alt}
                width={42}
                height={42}
                className={styles.logo}
              />
            </li>
          ))}
        </ul>
      )}
    </article>
  );
};
