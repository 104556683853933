import type { FC } from "react";
import type { Proposition } from "@motain/xpa-proto-files-web/lib/types/proposition";
import styles from "./ValuePropositionBanner.module.scss";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import classNames from "classnames";
import Link from "next/link";
import { ButtonLink } from "@/components/button/ButtonLink";
import { isNullable } from "@/types/isNullable";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";

type ValuePropositionBannerProps = Proposition & { isPreloaded?: boolean };

export const ValuePropositionBanner: FC<ValuePropositionBannerProps> = (
  props,
) => {
  const { link, text, isPreloaded = false } = props;

  if (isNullable(link)) {
    return null;
  }

  return (
    <div className={classNames(styles.container)}>
      <ImageWithFallback
        fill
        src={
          "https://images.onefootball.com/cw/bg/zig-zag-pattern-hype-green.svg"
        }
        alt={""}
        isPreloaded={isPreloaded}
        className={styles.pattern}
      />
      <div className={styles.content}>
        <p className={classNames("title-7-medium", styles.text)}>{text}</p>
        <Link
          href={{
            pathname: link.urlPath,
            query: link.queryParams,
          }}
          passHref={true}
          legacyBehavior={true}
        >
          <ButtonLink
            variant="outline"
            className={styles.link}
            onClick={() => {
              trackingService.sendXpaTracking(
                link.trackingEvents,
                EventType.EVENT_CLICK,
              );
            }}
          >
            {link.name}
          </ButtonLink>
        </Link>
      </div>
    </div>
  );
};
