import type { FC } from "react";
import type { EntityNavigationLink as XpaEntityNavigationLink } from "@motain/xpa-proto-files-web/lib/types/entity_navigation";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import styles from "./EntityNavigationLink.module.scss";
import classNames from "classnames";
import { isNonNullable } from "@/types/isNonNullable";
import Link from "next/link";

type EntityNavigationProps = XpaEntityNavigationLink & {
  hasLogoRounded?: boolean;
};

export const EntityNavigationLink: FC<EntityNavigationProps> = (props) => {
  const {
    urlPath,
    title,
    trackingEvents,
    logo,
    subtitle,
    hasLogoRounded = false,
  } = props;

  return (
    <Link
      className={styles.container}
      href={urlPath}
      aria-label={title}
      onClick={() => {
        trackingService.sendXpaTracking(trackingEvents, EventType.EVENT_CLICK);
      }}
    >
      <div className={styles.content}>
        {isNonNullable(logo) && (
          <ImageWithFallback
            width={42}
            height={42}
            alt={logo.alt}
            src={logo.path}
            darkModeSrc={logo.darkModePath}
            className={hasLogoRounded ? styles.logoRounded : ""}
            fallbackImageSrc="https://images.onefootball.com/blogs_logos/circle_onefootball.png"
            fallbackImageSrcDark="https://images.onefootball.com/blogs_logos/circle_onefootball.png"
          />
        )}

        <div className={styles.titles}>
          <p className={classNames(styles.subtitle, "title-8-bold")}>
            {subtitle}
          </p>
          <p className={classNames(styles.title, "title-7-bold")}>{title}</p>
        </div>
      </div>
    </Link>
  );
};
